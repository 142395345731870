/** @format */

/* * {
  box-sizing: unset;
} */

body {
  margin: 0;
  background: #ededed;
  font-family: "Montserrat", sans-serif;
}

em {
  font-style: normal;
}

a {
  text-decoration: none;
  color: black;
}

.zsiq_flt_rel {
  display: none !important;
}

.cmplogo {
  display: none !important;
}

.datePickerStyle
  > .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root
  .MuiOutlinedInput-notchedOutline {
  width: 140px;
  border: none;
  border-width: 0px !important;
  border-style: none !important;
  margin-top: -15px;
  padding-right: 0px !important;
  border-radius: 0px;
}

.datePickerStyle > .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding-top: 2px !important;
  padding-bottom: 0px !important;
}

.jss431 > .css-1uvydh2 {
  padding-top: 2px !important;
  padding-bottom: 0px !important;
}

.css-1q60rmi-MuiAutocomplete-endAdornment {
  margin-top: -8px;
}
.jss431 > .css-igs3ac {
  width: 140px !important;
  border: none !important;
  border-width: 0px !important;
  border-style: none !important;
  margin-top: -15px !important;
  padding-right: 0px !important;
  border-radius: 0px !important;
}

.datePicker
  > .MuiOutlinedInput-notchedOutline
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-bottom: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.css-ahj2mt-MuiTypography-root {
  line-height: 1 !important;
  font-size: 16px !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: #c90000 !important;
}

.css-1nr2wod.Mui-checked + .MuiSwitch-track {
  background-color: #c90000 !important;
}

.css-umzx0k-MuiClock-pin {
  background-color: #c90000 !important;
}

.css-d0vs79-MuiClockPointer-root {
  background-color: #c90000 !important;
}

.css-eg3pzz-MuiClockPointer-thumb {
  background-color: #c90000 !important;
  border: 16px solid #c90000 !important;
}

.css-rdq5h4-MuiClockPointer-root {
  background-color: #c90000 !important;
}

.css-hdw1oc {
  float: right !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #c90000 !important;
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #c90000 !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #c90000 !important;
}
